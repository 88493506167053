import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ScaleButton,
  ScaleSwitch,
  ScaleLink,
  ScaleDivider, ScaleModal,
} from '@telekom/scale-components-react';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import {
  Grid,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';

import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import Auth from '../../../api/auth';
import Provision from '../../../api/provision';
import useApiCall from '../../../hooks/useApiCall';
import { regexValidator } from '../../../helpers/regexValidator';
import Section from '../../../components/Section';
import Success from '../../../components/Success';
import {
  FormikNumberField,
  FormikTextField,
  FormikSelect,
} from '../../../components/Formik/index';

const ramToGB = (ram) => +(ram / 1024).toFixed(3);
const ramToMB = (ram) => ram * 1024;

const initialValues = {
  osProjectName: '',
  projectId: '',
  productId: '',
  cloudName: '',
  cpuCores: '',
  ram: '',
  diskQuota: '',
  floatingIps: '',
  internetConnectivity: false,
  DTHConnectivity: false,
  DTHRedRouterConnectivity: false,
  juiceGroup: '',
  unusedQuota: {},
  externalNetworks: [],
};

const numberInputOnWheelPreventChange = (e) => {
  e.target.blur();
  e.stopPropagation();
  requestAnimationFrame(() => e.target.focus());
};

const ProjectField = () => {
  const fetchProjects = useApiCall(Auth.fetchV2Projects);
  const [projects, setProjects] = useState([]);
  const { values: { osProjectName }, setValues } = useFormikContext();
  const menuItems = projects.map((project) => ({
    value: project._id,
    label: project.name,
  }));

  useEffect(() => {
    async function getProjects() {
      const [data] = await fetchProjects({}, false);
      if (data) setProjects(data);
    }
    getProjects();
  }, []);

  return (
    <FormikSelect
      name="projectId"
      label="Project"
      menuItems={menuItems}
      onChange={(e) => {
        setValues({
          ...initialValues,
          osProjectName,
          projectId: e.target.value,
        });
      }}
    />
  );
};

const ProductsField = () => {
  const fetchProducts = useApiCall(Auth.fetchV2Products);
  const userData = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const { values: { projectId, osProjectName }, setValues } = useFormikContext();
  const menuItems = products.map((product) => ({
    value: product._id,
    label: product.name,
  }));

  useEffect(() => {
    async function getProducts() {
      const [data] = await fetchProducts({ projectId, type: 'compute' });
      if (data) {
        setProducts(data.filter((product) => (
          product.approved && userData.productIds.includes(product._id)
        )));
      }
    }
    if (projectId) {
      getProducts();
    }
  }, [projectId]);

  return (
    <FormikSelect
      name="productId"
      label="Product"
      disabled={!projectId}
      menuItems={menuItems}
      onChange={(e) => {
        const selectedProductId = e.target.value;
        const selectedProduct = products.find((p) => p._id === selectedProductId);
        setValues({
          ...initialValues,
          juiceGroup: selectedProduct ? selectedProduct.details.juiceGroup : '',
          osProjectName,
          projectId,
          productId: selectedProductId,
        });
      }}
    />
  );
};

const CloudField = () => {
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);
  const fetchNetworkPolicies = useApiCall(Provision.fetchNetworkPolicies);
  const [availableVirtualClouds, setAvailableVirtualClouds] = useState([]);
  const {
    values: {
      juiceGroup, osProjectName, projectId, productId,
    },
    setFieldValue, setValues,
  } = useFormikContext();
  const menuItems = availableVirtualClouds.map((vc) => ({
    value: vc.cloud.name,
    label: vc.cloud.name,
  }));

  async function getAvailableClouds() {
    const [data] = await getVirtualCloudsCall({ juiceGroups: [juiceGroup] });
    if (data) {
      // VirtualClouds decorated with an extra `unusedQuota` field
      setAvailableVirtualClouds(data.map((vc) => ({
        ...vc,
        unusedQuota: {
          compute: {
            cores: vc.allowed_quota.compute.cores - vc.used_quota.compute.cores,
            ram: ramToGB(vc.allowed_quota.compute.ram - vc.used_quota.compute.ram),
          },
          volume: {
            gigabytes: vc.allowed_quota.volume.gigabytes - vc.used_quota.volume.gigabytes,
          },
          network: {
            floatingIps: vc.allowed_quota.network.floating_ips - vc.used_quota.network.floating_ips,
          },
        },
      })));
    }
  }

  useEffect(() => {
    if (juiceGroup) {
      getAvailableClouds();
    }
  }, [juiceGroup]);

  async function getExternalNetworks(cloudName) {
    const [data] = await fetchNetworkPolicies(juiceGroup, cloudName);
    const externalNetworks = data?.[0]?.access_as_external?.map((ext) => ext.network_name) || [];
    setFieldValue('externalNetworks', externalNetworks);
  }

  const handleCloudChange = (e) => {
    const selectedCloudName = e.target.value;

    if (selectedCloudName) {
      const selectedCloud = availableVirtualClouds.find(
        (vc) => vc.cloud.name === selectedCloudName,
      );
      getExternalNetworks(selectedCloudName);
      setValues({
        ...initialValues,
        juiceGroup,
        unusedQuota: selectedCloud?.unusedQuota || {},
        osProjectName,
        projectId,
        productId,
        cloudName: selectedCloudName,
      });
    } else {
      // Handle deselection case
      setValues({
        ...initialValues,
        osProjectName,
        juiceGroup,
        projectId,
        productId,
        cloudName: '',
        unusedQuota: {},
        externalNetworks: [],
      });
    }
  };

  return (
    <FormikSelect
      name="cloudName"
      label="Cloud"
      disabled={!juiceGroup}
      menuItems={menuItems}
      onChange={handleCloudChange}
    />
  );
};

const CpuCoresField = () => {
  const { values: { unusedQuota } } = useFormikContext();
  const availableCPUCores = unusedQuota.compute?.cores;
  useField({
    name: 'cpuCores',
    validate: (value) => {
      if (value > availableCPUCores) return `You can use up to ${availableCPUCores} CPU cores`;
      return undefined;
    },
  });

  useEffect(() => {
    if (availableCPUCores) {
      const inputElement = document.querySelector('input[name="cpuCores"]');
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [availableCPUCores]);

  return (
    <FormikNumberField
      name="cpuCores"
      label="CPU Cores"
      disabled={!availableCPUCores}
      onWheel={numberInputOnWheelPreventChange}
      placeholder={`Enter CPU Cores (${availableCPUCores} available)`}
    />
  );
};

const RamField = () => {
  const { values: { unusedQuota } } = useFormikContext();
  const availableRam = unusedQuota.compute?.ram;

  useField({
    name: 'ram',
    validate: (value) => {
      if (value > availableRam) return `You can use up to ${availableRam} GB of RAM`;
      return undefined;
    },
  });

  return (
    <FormikNumberField
      name="ram"
      label="Ram GB"
      disabled={!availableRam}
      onWheel={numberInputOnWheelPreventChange}
      placeholder={`Enter Ram GB (${unusedQuota.compute?.ram} GB available)`}
    />
  );
};

const DiskQuotaField = () => {
  const { values: { unusedQuota } } = useFormikContext();
  const availableDiskQuota = unusedQuota.volume?.gigabytes;
  useField({
    name: 'diskQuota',
    validate: (value) => {
      if (value > availableDiskQuota) return `You can use up to ${availableDiskQuota} GB`;
      return undefined;
    },
  });

  return (
    <FormikNumberField
      name="diskQuota"
      label="Disk Quota"
      disabled={!availableDiskQuota}
      onWheel={numberInputOnWheelPreventChange}
      placeholder={`Enter Total Disk Quota (${unusedQuota.volume?.gigabytes} GB available)`}
    />
  );
};

const FloatingIpsField = () => {
  const { values: { unusedQuota } } = useFormikContext();
  const availableFloatingIps = unusedQuota.network?.floatingIps;
  useField({
    name: 'floatingIps',
    validate: (value) => {
      if (value > availableFloatingIps) return `You can use up to ${availableFloatingIps} floating IPs`;
      return undefined;
    },
  });

  return (
    <FormikNumberField
      name="floatingIps"
      label="Floating Ips"
      disabled={!availableFloatingIps}
      onWheel={numberInputOnWheelPreventChange}
      placeholder={`Enter Floating Ips (${unusedQuota.network?.floatingIps} available)`}
    />
  );
};

const InternetConnectivityField = () => {
  const {
    values: { externalNetworks, internetConnectivity },
    setFieldTouched,
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField({ name: 'internetConnectivity' });

  return (
    <div className="form-field">
      <ScaleSwitch
        {...field}
        checked={internetConnectivity}
        disabled={!externalNetworks.includes('external_internet_provider')}
        label="Internet Connectivity"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldValue('internetConnectivity', e.detail.value);
          setFieldTouched('internetConnectivity');
        }}
      />
    </div>
  );
};

const DTHConnectivityField = () => {
  const {
    values: { externalNetworks, DTHConnectivity },
    setFieldTouched,
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField({ name: 'DTHConnectivity' });

  return (
    <div className="form-field">
      <ScaleSwitch
        {...field}
        checked={DTHConnectivity}
        disabled={!externalNetworks.includes('external_pi-net_provider')}
        label="DTH Connectivity"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldValue('DTHConnectivity', e.detail.value);
          setFieldTouched('DTHConnectivity');
        }}
      />
    </div>
  );
};

const DTHRedRouterConnectivityField = () => {
  const {
    values: { externalNetworks, DTHRedRouterConnectivity },
    setFieldTouched,
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField({ name: 'DTHRedRouterConnectivity' });

  return (
    <div className="form-field">
      <ScaleSwitch
        {...field}
        checked={DTHRedRouterConnectivity}
        disabled={!externalNetworks.includes('external_pi-net2inet_provider')}
        label="DTH Connectivity with Internet (Red Router)"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldValue('DTHRedRouterConnectivity', e.detail.value);
          setFieldTouched('DTHRedRouterConnectivity');
        }}
      />
    </div>
  );
};

const ComputeOrder = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const createOsProject = useApiCall(Provision.createOsProject);
  const [osProjectOrdered, setOsProjectOrdered] = useState(false);

  const validationSchema = Yup.object().shape({
    osProjectName: Yup.string().required('Required').matches(regexValidator.noSpace, 'Spaces not allowed'),
    projectId: Yup.string().required('Required'),
    productId: Yup.string().required('Required'),
    cloudName: Yup.string().required('Required'),
    cpuCores: Yup.number().required('Required').min(0),
    ram: Yup.number().required('Required').min(0),
    diskQuota: Yup.number().required('Required').min(0),
    floatingIps: Yup.number().required('Required').min(0),
    internetConnectivity: Yup.boolean().required('Required'),
    DTHConnectivity: Yup.boolean().required('Required'),
    DTHRedRouterConnectivity: Yup.boolean().required('Required'),
  });

  const handleSubmit = async (values) => {
    const {
      osProjectName,
      projectId,
      productId,
      cloudName,
      cpuCores,
      ram,
      diskQuota,
      floatingIps,
      internetConnectivity,
      DTHConnectivity,
      DTHRedRouterConnectivity,
      juiceGroup,
    } = values;

    const [data] = await createOsProject({
      type: 'compute',
      osProjectName,
      cloudId: cloudName,
      juiceGroup,
      productId,
      projectId,
      requestedQuota: {
        compute: { cores: cpuCores, ram: ramToMB(ram) },
        network: {
          floatingIps,
          DTHRedRouterConnectivity,
          DTHConnectivity,
          internetConnectivity,
        },
        volume: { gigabytes: diskQuota, objectsMegabytes: 0 },
      },
    });
    if (data !== null) {
      setOsProjectOrdered(true);
    }
  };

  return (
    <Grid
      container
      direction="column"
      padding="3rem 10rem"
    >
      {osProjectOrdered && (
        <ScaleModal
          opened
          heading="Compute OS-Project Ordered"
          onScale-close={() => {
            navigate('/products/compute');
          }}
        >
          <Success
            messageId="createOsProjectFullfilled"
            onSubmit={() => {
              navigate('/products/compute');
            }}
          />
        </ScaleModal>
      )}
      <Section header="Openstack Project Request">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Project Name: </strong>
                    {intl.formatMessage({ id: 'projectName' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormikTextField
                    autoFocus
                    name="osProjectName"
                    label="OS Project Name"
                    placeholder="Enter a name for your Openstack Project"
                  />
                </Grid>
                <ScaleDivider />
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Project: </strong>
                    {intl.formatMessage({ id: 'projectDescription' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ProjectField />
                </Grid>
                <ScaleDivider />
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Product: </strong>
                    {intl.formatMessage({ id: 'productDescription' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ProductsField />
                </Grid>
                <ScaleDivider />
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Data Center: </strong>
                    {intl.formatMessage({ id: 'dataCenterDescription' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CloudField />
                </Grid>
                <ScaleDivider />
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Virtual CPUs and Memory: </strong>
                    {intl.formatMessage({ id: 'virtualCPUsAndMemory' })}
                    <br />
                    Calculate the total number of needed resources
                    by adding up the resources of the instances
                    that you plan to deploy. The list of available instance types can be found&nbsp;
                    <ScaleLink href="https://portal.pan-net.cloud/docs/Compute" rel="noopener" target="_blank">here</ScaleLink>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container direction="column">
                    <Grid item padding=".5rem 0">
                      <CpuCoresField />
                    </Grid>
                    <Grid item padding=".5rem 0">
                      <RamField />
                    </Grid>
                  </Grid>
                </Grid>
                <ScaleDivider />
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Storage: </strong>
                    Add block storage to your OpenStack project.
                    Block storage can then be attached to the instances you create.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DiskQuotaField />
                </Grid>
                <ScaleDivider />
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Floating IPs: </strong>
                    Using a floating IP, you can rapidly provide access
                    from an external network to the instance with no public network interface.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FloatingIpsField />
                </Grid>
                <ScaleDivider />
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Internet Connectivity: </strong>
                    This option includes the demanded (predefined) number of public floating IPs,
                    the creation of an internal network
                    and the creation of a virtual router configured for internet connectivity.
                    <br />
                    In case this option is disabled, only the deamnded  floating IPs are provided,
                    and no networks or routers are created.
                    Internet connectivity can be configured using JUICE API at a later stage
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InternetConnectivityField />
                </Grid>
                <ScaleDivider />
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>DTH Connectivity: </strong>
                    This option includes the demanded (predefined) number of DTH floating IPs,
                    the creation of an internal network
                    and the creation of a virtual router configured for DTH connectivity.
                    <br />
                    In case this option is disabled, only the demanded floating IPs are provided,
                    and no networks or routers are created.
                    DTH connectivity can be configured using JUICE API at a later stage as well.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DTHConnectivityField />
                </Grid>
                <ScaleDivider />
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>DTH Red Router Connectivity: </strong>
                    This option includes the demanded (predefined) number of DTH floating IPs,
                    the creation of an internal network,
                    the creation of a virtual router configured for DTH and internet connectivity.
                    <br />
                    In case this option is disabled, only the demanded floating IPs are provided,
                    and no networks or routers are created.
                    DTH and internet connectivity can be configured
                    using JUICE API at a later stage as well.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DTHRedRouterConnectivityField />
                </Grid>
                <ScaleDivider />
              </Grid>
              <div className="form-buttons">
                <ScaleButton disabled={!dirty || !isValid} variant="primary">
                  Submit
                </ScaleButton>
              </div>
            </Form>
          )}
        </Formik>
      </Section>
    </Grid>
  );
};


export default ComputeOrder;
