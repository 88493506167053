import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import { ScaleButton, ScaleDropdownSelect, ScaleDropdownSelectItem } from '@telekom/scale-components-react';

import ProductUnassignment from './ProductUnassignment';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';


const RoleField = ({ roles }) => {
  const { setFieldTouched } = useFormikContext();
  const [field] = useField({ name: 'roles' });
  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="Role"
        floatingStrategy="fixed"
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('roles');
        }}
      >
        {Object.keys(roles).map(
          (key) => (
            <ScaleDropdownSelectItem key={key} value={key}>
              {roles[key]}
            </ScaleDropdownSelectItem>
          ),
        )}
      </ScaleDropdownSelect>
    </div>
  );
};

RoleField.propTypes = { roles: PropTypes.objectOf(PropTypes.string).isRequired };


const OrganizationField = ({ organizations, onChange }) => {
  const { setFieldTouched } = useFormikContext();
  const [field] = useField({ name: 'organizationId' });

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="Organization"
        floatingStrategy="fixed"
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('organizationId');
          onChange(e);
        }}
      >
        {organizations.map((org) => (
          <ScaleDropdownSelectItem key={org._id} value={org._id}>
            {org.enterpriseName}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

OrganizationField.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      enterpriseName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

const EditUserForm = ({
  onSubmit, onCancel, user, roles, canEditOrg,
}) => {
  const validationSchema = Yup.object().shape({
    roles: Yup.string().required('Required'),
    organizationId: Yup.string().required('Required'),
  });

  const [confirmSave, setConfirmSave] = useState(false);
  const [userResources, setUserResources] = useState({});
  const [organizations, setOrganizations] = useState([]);

  const getUserResourcesCall = useApiCall(Auth.fetchV1UserResources);
  const getOrganizationsCall = useApiCall(Auth.fetchV1Organizations);

  const getUserResources = async () => {
    if (!userResources.products?.length) {
      const [data] = await getUserResourcesCall(user.uuid);
      setUserResources(data);
    }
  };

  const getOrganizations = async () => {
    const [data] = await getOrganizationsCall();
    setOrganizations(data);
  };

  const onOrgChange = (e) => {
    if (e.detail.value !== user.organization._id && userResources.products.length) {
      setConfirmSave(true);
    } else {
      setConfirmSave(false);
    }
  };

  const initialValues = {
    roles: user.role,
    organizationId: user.organization?._id,
  };

  useEffect(() => {
    if (canEditOrg) getOrganizations();
    getUserResources();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <RoleField roles={roles} />
          {canEditOrg && organizations.length && (
            <OrganizationField
              organizations={organizations}
              onChange={onOrgChange}
            />
          )}
          {confirmSave && (
            <ProductUnassignment resources={userResources} />
          )}
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Save
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={() => {
                onCancel();
                setConfirmSave(false);
              }}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  roles: PropTypes.objectOf(PropTypes.string).isRequired,
  canEditOrg: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    organization: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default EditUserForm;
