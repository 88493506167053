import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Table from '../../../../../components/Table';

import { fetchMachineAccounts } from '../../../../../redux/safescarf/machineAccounts/actions';
import { fetchProductMembers } from '../../../../../redux/safescarf/productMembers/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';

import { getHigherRole } from '../services/roles';


function getMember(user, productMembers, isMachineAccount) {
  const productMember = productMembers.find((pm) => pm.user === user.id);

  return {
    userId: user.id,
    username: user.username,
    createdBy: user.email,
    globalRole: user.globalRole,
    productMemberId: productMember?.id,
    productMemberRole: productMember?.role,
    isMachineAccount,
  };
}

function getMembers(safescarfUsers, machineAccounts, productMembers) {
  const activeSafescarfUsers = safescarfUsers.filter((user) => user.is_active);
  const activeMachineAccounts = machineAccounts.filter((ma) => ma.is_active);
  const decoratedSafescarfUsers = activeSafescarfUsers.map(
    (user) => getMember(user, productMembers, false),
  );
  const decoratedMachineAccounts = activeMachineAccounts.map(
    (ma) => getMember(ma, productMembers, true),
  );
  return [...decoratedSafescarfUsers, ...decoratedMachineAccounts];
}


const ProductMembers = ({ safescarfUsers }) => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [members, setMembers] = useState([]);

  const roles = useSelector((state) => state.safescarf.roles);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);
  const productMembers = useSelector((state) => state.safescarf.productMembers
    .filter((pm) => pm.product === Number(productId)), shallowEqual);

  useEffect(() => {
    if (!roles.length) dispatch(fetchRoles());
    if (!machineAccounts.length) dispatch(fetchMachineAccounts());
    if (!productMembers.length) dispatch(fetchProductMembers());
  }, []);

  useEffect(() => {
    if (safescarfUsers.length || machineAccounts.length) {
      setMembers(getMembers(safescarfUsers, machineAccounts, productMembers));
    }
  }, [safescarfUsers, machineAccounts, productMembers]);

  const memberRows = members.map((member) => ({
    key: member.userId.toString(),
    onRowClick: () => navigate(`members/${member.userId.toString()}`),
    data: [
      {
        field: 'safescarfUserName',
        value: member.username,
      },
      {
        field: 'isMachineAccount',
        value: member.isMachineAccount ? 'Machine account' : 'User',
      },
      {
        field: 'createdBy',
        value: member.createdBy,
      },
      {
        field: 'productMemberRole',
        value: getHigherRole(roles, member.globalRole, member.productMemberRole),
      },
    ],
  }));


  return (
    <Table
      header="Members"
      fields={[
        {
          id: 'safescarfUserName',
          title: 'Username',
        },
        {
          id: 'isMachineAccount',
          title: 'Account type',
        },
        {
          id: 'createdBy',
          title: 'Created by',
        },
        {
          id: 'productMemberRole',
          title: 'Permission',
        },
      ]}
      rows={memberRows || []}
    />
  );
};

ProductMembers.propTypes = {
  safescarfUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      email: PropTypes.string,
      is_active: PropTypes.bool,
    }),
  ).isRequired,
};

export default ProductMembers;
